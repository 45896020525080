import { useContext, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  authFail,
  authStart,
  disableAuthLoading,
  resetAuthLocalError,
  selectLocalError,
  selectTokens,
  setUnitError,
  SiteContext,
  updateUser,
} from '@homeplay/app';
import { getValidPhoneNumber } from '@homeplay/utils';
import {
  confirmAuthOTP,
  handleError,
  sendAuthOTP,
  startPhoneEdit,
} from '@homeplay/api';
import { useNotification } from '@homeplay/hooks';
import { Loader, Page } from '@homeplay/components';
import PhoneInput from '@homeplay/components/src/UI/Input/PhoneInput';
import Button from '@homeplay/components/src/UI/Button';
import VerifyPhone from '../Auth/VerifyPhone';
import classes from '../UserAccount.module.scss';

const editPhoneSteps = {
  newPhone: 1,
  verifyPhone: 2,
};

const EditPhone = () => {
  const { siteName, isTouchUnit, screenKeyboard } = useContext(SiteContext);
  const [step, setStep] = useState(editPhoneSteps.newPhone);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const focusInput = useRef();
  const tokens = useSelector(selectTokens);
  const otpError = useSelector(selectLocalError);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { editPhoneNotification } = useNotification();

  useEffect(() => {
    if (focusInput.current) {
      focusInput.current.focus();
    }
  }, []);

  const newPhoneConfirm = async (event) => {
    event.preventDefault();
    try {
      setIsLoading(true);
      await startPhoneEdit(tokens?.access?.token, phoneNumber);
      setStep(editPhoneSteps.verifyPhone);
    } catch (error) {
      dispatch(setUnitError({ error: handleError(error) }));
    } finally {
      setIsLoading(false);
    }
  };

  const otpConfirm = async (otpCode) => {
    try {
      setIsLoading(true);
      const phoneToken = await confirmAuthOTP(phoneNumber, otpCode);
      dispatch(updateUser({ phoneToken }, siteName, isTouchUnit));
      navigate(`/${siteName}`);
      editPhoneNotification();
    } catch (error) {
      dispatch(
        authFail({
          error: handleError(error),
          localError: true,
        })
      );
    } finally {
      setIsLoading(false);
    }
  };

  const isPhoneValid = () => {
    return !!getValidPhoneNumber(phoneNumber);
  };

  const resendOtp = async () => {
    dispatch(authStart());
    try {
      await sendAuthOTP(phoneNumber);
      dispatch(resetAuthLocalError());
      dispatch(disableAuthLoading());
      return true;
    } catch (error) {
      dispatch(authFail({ error: handleError(error) }));
    }
    return false;
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      {step === editPhoneSteps.newPhone && (
        <Page
          headerContent={'Edit Phone'}
          backPath={'../profile'}
          customClass={
            classes.Auth +
            (isTouchUnit && !!screenKeyboard ? ' screen-keyboard' : '')
          }
        >
          <div className="container">
            <form name="form" onSubmit={newPhoneConfirm}>
              <label className="label" htmlFor="phone">
                Your New Phone Number
              </label>
              <PhoneInput
                id="phone"
                onChange={setPhoneNumber}
                value={phoneNumber}
                focus={true}
                autocomplete="tel"
              />

              <Button disabled={!isPhoneValid()} customClass="d-block">
                Next
              </Button>
            </form>
          </div>
        </Page>
      )}

      {step === editPhoneSteps.verifyPhone && (
        <VerifyPhone
          title="Verify Your Phone Number"
          label="To complete edit phone"
          phoneNumber={phoneNumber}
          onSubmit={otpConfirm}
          resendOtp={resendOtp}
          otpError={otpError}
          isEditPhone
        />
      )}
    </>
  );
};

export default EditPhone;
