import axios from './axiosSRP';
import { getValidPhoneNumber } from '@homeplay/utils';

export const getLocalUserParams = (siteName, isLandBased) => {
  const storageKey = `${siteName ? siteName : 'hp'}-auth`;

  let userJSON = isLandBased
    ? sessionStorage.getItem(storageKey)
    : localStorage.getItem(storageKey);

  if (!userJSON) {
    return null;
  }
  try {
    const data = JSON.parse(userJSON);
    if (data.access && data.refresh) {
      return data;
    }
  } catch (e) {
    return null;
  }
  return null;
};

export const setLocalUserParams = (params, siteName, isLandBased) => {
  const storageKey = `${siteName ? siteName : 'hp'}-auth`;
  const data = JSON.stringify(params);

  isLandBased
    ? sessionStorage.setItem(storageKey, data)
    : localStorage.setItem(storageKey, data);
};

export const getValidUserTokens = async (tokens, siteName, isTouchUnit) => {
  const currentDate = Date.now();
  if (currentDate > tokens.access.expires) {
    if (currentDate < tokens.refresh.expires) {
      return await refreshToken(tokens.refresh.token, siteName, isTouchUnit);
    } else {
      return null;
    }
  }
  return tokens;
};

export const startPhoneEdit = async (accessToken, newPhone) => {
  const data = await axios.post(
    'hp/users/start_phone_edit',
    { phone: getValidPhoneNumber(newPhone) },
    {
      headers: { Authorization: `Bearer ${accessToken}` },
    }
  );

  return data;
};

export const confirmAuthOTP = async (phoneNumber, otpCode) => {
  const params = {
    phone: getValidPhoneNumber(phoneNumber),
    otp: otpCode,
  };
  const { data } = await axios.post('hp/users/confirm_phone', params);
  return data.phone_token;
};

export const startRegistration = async (phoneNumber, siteName) => {
  const params = {
    phone: getValidPhoneNumber(phoneNumber),
    site_slug: siteName ? siteName : process.env.REACT_APP_SITE_NAME,
  };
  const { data } = await axios.post('hp/users/start_registration', params);
  return data;
};

export const sendAuthOTP = async (phoneNumber) => {
  const params = {
    phone: getValidPhoneNumber(phoneNumber),
  };
  const { data } = await axios.post('hp/users/send_otp', params);
  return data;
};

export const createUser = async (phoneToken, password, attrs, siteName) => {
  const { data } = await axios.post('hp/users/create', {
    phone_token: phoneToken,
    password,
    first_name: attrs.firstName,
    last_name: attrs.lastName,
    site_slug: siteName ? siteName : process.env.REACT_APP_SITE_NAME,
  });
  return parseUserData(data);
};

export const loginUser = async (phoneNumber, password, siteName) => {
  const params = {
    login: getValidPhoneNumber(phoneNumber),
    password,
    provider: 'identity',
    site_slug: siteName ? siteName : process.env.REACT_APP_SITE_NAME,
  };
  const { data } = await axios.post('/hp/users/login', params);
  return parseUserData(data);
};

export const getUser = async (accessToken) => {
  const { data } = await axios.get('hp/users', {
    headers: { Authorization: `Bearer ${accessToken}` },
  });
  return parseUser(data.user);
};

export const updateUserData = async (accessToken, attrs) => {
  const params = {
    first_name: attrs.firstName,
    last_name: attrs.lastName,
    phone_token: attrs.phoneToken,
  };

  const { data } = await axios.post('hp/users', params, {
    headers: { Authorization: `Bearer ${accessToken}` },
  });

  return parseUser(data.user);
};

export const resetPasswordRequest = async (phoneNumber, otp, siteName) => {
  const params = {
    phone: getValidPhoneNumber(phoneNumber),
    otp,
    site_slug: siteName ? siteName : process.env.REACT_APP_SITE_NAME,
  };
  const { data } = await axios.post('hp/users/password/token/create', params);
  return data;
};

export const setNewPassword = async (
  phoneNumber,
  passwordToken,
  password,
  siteName
) => {
  const params = {
    login: getValidPhoneNumber(phoneNumber),
    password_token: passwordToken,
    password,
    site_slug: siteName ? siteName : process.env.REACT_APP_SITE_NAME,
  };
  const { data } = await axios.post('hp/users/password', params);
  return data;
};

export const createBankAccount = async (accessToken, attrs) => {
  const params = {
    bank_name: attrs.bankName,
    holder_name: attrs.holderName,
    number: attrs.number,
  };
  const { data } = await axios.post(
    'hp/users/player_bank_accounts/create',
    params,
    { headers: { Authorization: `Bearer ${accessToken}` } }
  );
  return parseUser(data.user);
};

export const updateBankAccount = async (accessToken, attrs) => {
  const params = {
    id: attrs.id,
    bank_name: attrs.bankName,
    holder_name: attrs.holderName,
    number: attrs.number,
  };
  const { data } = await axios.post('hp/users/player_bank_accounts', params, {
    headers: { Authorization: `Bearer ${accessToken}` },
  });
  return parseUser(data.user);
};

export const deleteBankAccount = async (accessToken, accountId) => {
  const { data } = await axios.post(
    'hp/users/player_bank_accounts/delete',
    { id: accountId },
    { headers: { Authorization: `Bearer ${accessToken}` } }
  );
  return parseUser(data.user);
};

export const startUserVerification = async (
  accessToken,
  siteName,
  requiredVerificationLevel
) => {
  const redirectUrl = `${location.origin}/${siteName ? siteName : ''}`;

  const { data } = await axios.post(
    'hp/users/kyc/start_verification',
    {
      redirect_url: redirectUrl,
      required_verification_level: requiredVerificationLevel,
    },
    {
      headers: { Authorization: `Bearer ${accessToken}` },
    }
  );

  return data.form_url;
};

export const getUserLastVerificationStatus = async (accessToken) => {
  const { data } = await axios.get('hp/users/kyc/get_last_verification', {
    headers: { Authorization: `Bearer ${accessToken}` },
  });

  return data;
};

export const getBanksList = () => {
  return [
    { value: 'ABSA BANK', label: 'ABSA Bank' },
    { value: 'AFRICAN BANK', label: 'African Bank' },
    { value: 'BIDVEST BANK', label: 'Bidvest Bank' },
    { value: 'CAPITEC BANK LIMITED', label: 'Capitec Bank' },
    { value: 'DISCOVERY BANK', label: 'Discovery Bank' },
    { value: 'FIRST NATIONAL BANK', label: 'First National Bank' },
    { value: 'INVESTEC BANK LIMITED', label: 'Investec Bank' },
    { value: 'NEDBANK LIMITED', label: 'Nedbank' },
    { value: 'SASFIN BANK', label: 'Sasfin Bank' },
    { value: 'STANDARD BANK', label: 'Standard Bank' },
    { value: 'TYME BANK', label: 'Tyme Bank' },
  ];
};

const refreshToken = async (refreshToken, siteName, isTouchUnit) => {
  const params = {
    token: refreshToken,
  };
  const { data } = await axios.post('hp/users/refresh_token', params);

  const userTokens = {
    access: { token: data.access.token, expires: data.access.expires * 1000 },
    refresh: {
      token: data.refresh.token,
      expires: data.refresh.expires * 1000,
    },
  };
  setLocalUserParams(userTokens, siteName, isTouchUnit);
  return userTokens;
};

const parseUserData = (data) => {
  return {
    user: parseUser(data.user),
    userTokens: {
      access: { token: data.access.token, expires: data.access.expires * 1000 },
      refresh: {
        token: data.refresh.token,
        expires: data.refresh.expires * 1000,
      },
    },
  };
};

const parseUser = (userData) => {
  return {
    id: userData.id,
    phone: userData.phone,
    email: userData.email,
    firstName: userData.first_name,
    lastName: userData.last_name,
    bankAccounts: getUserBankAccounts(userData.player_bank_accounts),
    verificationLevel: userData.verification_level,
  };
};

const getUserBankAccounts = (accountsList) => {
  return accountsList.map((account) => ({
    bankName: account.bank_name,
    holderName: account.holder_name,
    id: account.id,
    number: account.number,
  }));
};
